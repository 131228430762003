// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.section-order__header {
    margin-bottom: 40px;
    margin-top: 40px;
    text-align: center;
}

.info-block {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    background-color: #dfdfdf;
    border-radius: 4px;
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.row {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.row-title{
    font-weight: bold;
}

@media (max-width: 768px) {
    .info-block {
        flex-direction: column;
    }

}`, "",{"version":3,"sources":["webpack://./src/pages/Order/order.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,6BAA6B;IAC7B,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;AAEJ","sourcesContent":["\n.section-order__header {\n    margin-bottom: 40px;\n    margin-top: 40px;\n    text-align: center;\n}\n\n.info-block {\n    display: flex;\n    gap: 20px;\n    justify-content: space-around;\n    background-color: #dfdfdf;\n    border-radius: 4px;\n    padding: 20px;\n    max-width: 900px;\n    margin: 0 auto;\n    margin-bottom: 20px;\n}\n\n.row {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    margin-bottom: 10px;\n}\n\n.row-title{\n    font-weight: bold;\n}\n\n@media (max-width: 768px) {\n    .info-block {\n        flex-direction: column;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
