import Sidebar from "../components/sidebar/Sidebar";
import CustomerRow from "../components/orderrow/CustomerRow";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

const Customers = () => {
    const axiosPrivate = useAxiosPrivate();
  const [usersData, setUsers] = useState([]);


  useEffect(() => {
    // Fetch data from the backend
    const fetchUsersData = async () => {
        try {
            const response = await axiosPrivate.get("/api/v1/private/users/get");
            const users = response.data.map(user => ({
                id: user.user_id,
                name: user.username,
                email: user.email,
                telegram: user.telegram,
                phone: user.phone,
            }));
            
            setUsers(users);
        } catch (error) {
            console.error("Error fetching cart data:", error);
        }
    };

    fetchUsersData();
}, [axiosPrivate]);

    const usrs = usersData.map((user) => {
        return <CustomerRow
        user={user}
        key={user.id}
        />
    })

    return ( 
        <div>
            
            <Sidebar />
            <div className="main-content">
            <h1>Клиенты</h1>

            <div className="orders-list">
            <div className="orders-header">
        <div className="order-cell">Имя</div>
        <div className="order-cell">Telegram</div>
        <div className="order-cell">Телефон</div>
        <div className="order-cell">Email</div>
      </div>
                {usrs}
            </div>
            
            </div>
        </div>
     );
}
 
export default Customers;