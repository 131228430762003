// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-section {
    padding: 20px;
    text-align: left;
}

.loading-spinner {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
    margin-left: 10px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Orders/orders.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,sBAAsB;IACtB,uCAAuC;IACvC,iBAAiB;EACnB;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".buttons-section {\n    padding: 20px;\n    text-align: left;\n}\n\n.loading-spinner {\n    display: inline-block;\n    width: 24px;\n    height: 24px;\n    border: 3px solid rgba(0, 0, 0, 0.3);\n    border-radius: 50%;\n    border-top-color: #000;\n    animation: spin 1s ease-in-out infinite;\n    margin-left: 10px;\n  }\n  \n  @keyframes spin {\n    to {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
