import React, { useState } from 'react';
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faUsers, faReceipt,faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

import logo from "./../../img/Pwb_white_wide.png"

const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();
  
  const signOut = async () => {
    await logout();
    navigate('/');
}
  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      
      <div className={`sidebar ${isActive ? 'active' : ''}`}>
        
        <div className='sidebar-header'>
        <div className="sidebar-logo">
        <img src={logo} alt="" className="sidebar-logo"/>
        </div>
        <button className="toggle-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </button>
        </div>
        
        <nav className="sidebar-nav">
          <NavLink exact to="/" activeClassName="active">
            <FontAwesomeIcon icon={faHome} className="icon" /> <span className="text">Главная</span>
          </NavLink>
          <NavLink to="/orders" activeClassName="active">
            <FontAwesomeIcon icon={faShoppingCart} className="icon" /> <span className="text">Заказы</span>
          </NavLink>
          <NavLink to="/customers" activeClassName="active">
            <FontAwesomeIcon icon={faUsers} className="icon" /> <span className="text">Клиенты</span>
          </NavLink>

          <NavLink to="/requests" activeClassName="active">
            <FontAwesomeIcon icon={faReceipt} className="icon" /> <span className="text">Заявки</span>
          </NavLink>

          <button onClick={signOut} className="logout-button">
          <FontAwesomeIcon icon={faSignOutAlt} className="icon" /> <span className="text">Выход</span>
        </button>
        </nav>
       
      </div>
    </>
  );
};

export default Sidebar;