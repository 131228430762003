import "./dblocks.css"



const NumberBlock = ({name, value, icon}) => {
    return ( 
    
        
        <div className="dash-block">
            <div>
            <h2 style={{margin: '0px'}}>{value}</h2>
            <a>{name}</a>
            </div>
            <div className="number-icon">
            {icon}
            </div>
        </div>
     );
}
 
export default NumberBlock;