import Sidebar from "../../components/sidebar/Sidebar";
import RateBlock from "../../components/dblocks/RateBlock";
import NumberBlock from "../../components/dblocks/NumberBlock";
import GraphicBlock from "../../components/dblocks/GraphicBlock";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRub, faShoppingCart, faUsers } from '@fortawesome/free-solid-svg-icons';

import "./home.css"

const Home = () => {
    return ( 
        <div>
            
            <Sidebar />
            <div className="main-content">
            <h1>Главная</h1>
            <div className="dashboard-row">
            <RateBlock />
            
            </div>
            <div className="dashboard-row">
            <GraphicBlock />
            </div>
            <div className="dashboard-row">
            <NumberBlock 
                name = {'Выручка'}
                value = {'120 000'}
                icon = {<FontAwesomeIcon icon={faRub} className="icon" />}
                />
            <NumberBlock 
                name = {'Клиенты'}
                value = {'455'}
                icon = {<FontAwesomeIcon icon={faUsers} className="icon" />}
                />
            <NumberBlock 
                name = {'Заказы'}
                value = {'512'}
                icon = {<FontAwesomeIcon icon={faShoppingCart} className="icon" />}
                />
            </div>
            </div>
        </div>
     );
}
 
export default Home;