// RidRow.js

import React from 'react';
import PropTypes from 'prop-types';
import priceFormatter from '../utils/priceFormatter';

const RidInfo = ({ rid, image, name, price, amount, scas, link, model, category, weight, comment, expanded, deliveryPrice, onDeliveryPriceChange }) => {
    const ridsinfo = scas?.map((sca, index) => (
        <tr key={index}>
            <td><span style={styles.value}>{sca.size}</span></td>
            <td><span style={styles.value}>{sca.color}</span></td>
            <td><span style={styles.value}>{sca.amount}</span></td>
        </tr>
    ));

    const handleDeliveryPriceChange = (e) => {
        onDeliveryPriceChange(rid, parseFloat(e.target.value));
    };

    expanded = true;

    return (
        <div style={styles.container}>
            <div style={styles.row}>
                <div style={styles.img}><img src={'http://localhost:8080/api/v1/image/'+image} style={styles.img} alt={name} /></div>
                <span style={styles.label}>{name}</span>
                <span style={styles.value}>{priceFormatter(price * amount)}</span>
                <button style={styles.expandButton}>V</button>
            </div>
            <div style={expanded ? styles.info : styles.hidden}>
                <table style={styles.infoData}>
                    <tbody>
                        <tr>
                            <td><span style={styles.label}>Ссылка: </span></td>
                            <td><span style={styles.value}>{link}</span></td>
                        </tr>
                        <tr>
                            <td><span style={styles.label}>Модель: </span></td>
                            <td><span style={styles.value}>{model}</span></td>
                        </tr>
                        <tr>
                            <td><span style={styles.label}>Категория: </span></td>
                            <td><span style={styles.value}>{category}</span></td>
                        </tr>
                        <tr>
                            <td><span style={styles.label}>Цена: </span></td>
                            <td><span style={styles.value}>{price}</span></td>
                        </tr>
                        <tr>
                            <td><span style={styles.label}>Вес: </span></td>
                            <td><span style={styles.value}>{weight}</span></td>
                        </tr>
                    </tbody>
                </table>
                <table style={styles.infoData}>
                    <tbody>
                        <tr>
                            <td><span style={styles.label}>Размер</span></td>
                            <td><span style={styles.label}>Цвет</span></td>
                            <td><span style={styles.label}>Количество</span></td>
                        </tr>
                        {ridsinfo}
                    </tbody>
                </table>

                {comment ? (
              <div style={styles.delivery}>
              <span style={styles.label}>Комментарий: </span>
              <span style={styles.value}>{comment}</span>
              </div>
            ) : <></>
            }
                <div style={styles.delivery}>
                    <span style={styles.label}>Цена доставки:</span>
                    <input 
                        type="number" 
                        value={deliveryPrice} 
                        onChange={handleDeliveryPriceChange} 
                        step="0.01" 
                    />
                </div>
            </div>
        </div>
    );
};

RidInfo.propTypes = {
    rid: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    scas: PropTypes.array.isRequired,
    link: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    weight: PropTypes.number.isRequired,
    expanded: PropTypes.bool.isRequired,
    deliveryPrice: PropTypes.number.isRequired,
    onDeliveryPriceChange: PropTypes.func.isRequired
};

const styles = {
    container: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '0px',
        maxWidth: '800px',
        margin: '0 auto',
        marginBottom: '20px',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        backgroundColor: '#dfdfdf'
    },
    hidden: {
        display: 'none'
    },
    infoData: {
        textAlign: 'center',
        padding: '20px'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    delivery: {
      padding: '10px',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
  },
    img: {
        height: '100px',
        borderRadius: '10px'
    },
    label: {
        fontWeight: 'bold',
    },
    value: {
        color: '#555',
    },
    expandButton: {
        marginRight: '50px',
    }
};

export default RidInfo;
