// Order.js

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RidInfo from "../../components/RidRow";
import priceFormatter from "../../utils/priceFormatter";
import Sidebar from "../../components/sidebar/Sidebar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MessageTemplate from "../../components/MessageTemplate";

import { convertPrice } from '../../utils/priceConverter';
import "./order.css";


const statusMap = {
    4: 'Ожидает оплаты',
    5: 'Оплачен',
    6: 'Оформлен',
    7: 'Собран',
    8: 'В пути на склад',
    9: 'Отправлен получателю'
};

const reverseStatusMap = {
    'Ожидает оплаты': 4,
    'Оплачен': 5,
    'Оформлен': 6,
    'Собран': 7,
    'В пути на склад': 8,
    'Отправлен получателю': 9,
};

const Order = () => {
    const { id } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [orderData, setOrder] = useState({});
    const [user, setUser] = useState({});
    const [error, setError] = useState(null);
    const [editData, setEditData] = useState({
        status: '',
        delivery_price: '',
        track_number: ''
    });
    const [ridsData, setRidsData] = useState([]);

    const [convertedPrice, setConvertedPrice] = useState('');

    useEffect(() => {
        axiosPrivate.get("/api/v1/private/orders/get/" + id)
            .then(response => {
                setOrder(response.data);
                setEditData({
                    status: response.data.status,
                    delivery_price: response.data.delivery_price,
                    track_number: response.data.track_number
                });
                setRidsData(response.data.rids.map(rid => ({
                    rid: rid.rid,
                    delivery_price: rid.delivery_price || 0
                })));
                axiosPrivate.get("/api/v1/private/users/get/" + orderData?.user_id)
                    .then(response => {
                        setUser(response.data);
                    })
                    .catch(error => {
                        setError(error);
                    });
            })
            .catch(error => {
                setError(error);
            });

        
    }, [axiosPrivate, id, orderData.user_id]);



    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditData({
            ...editData,
            [name]: name === 'status' ? reverseStatusMap[value] : name === 'delivery_price' ? parseFloat(value) : value
        });
    };

    const handleRidDeliveryPriceChange = (ridId, newPrice) => {
        setRidsData(ridsData.map(rid => 
            rid.rid === ridId ? { ...rid, rid: ridId, delivery_price: newPrice } : rid
        ));
    };

    const handleSave = async () => {
        try {
            await axiosPrivate.post("/api/v1/private/orders/edit", {
                order_id: orderData.order_id,
                ...editData
            });
            await axiosPrivate.post("/api/v1/private/orders/edit/rids", ridsData);
            alert('Данные заказа успешно обновлены');
        } catch (error) {
            console.error("Error updating order:", error);
            alert('Failed to update order');
        }
    };

    const totalItemsPrice = orderData.rids?.reduce((total, rid) => total + (rid.price * rid.amount), 0) || 0;
    const comissionPrice = totalItemsPrice * 0.1
    const totalDeliveryPriceChina = ridsData.reduce((total, rid) => total + rid.delivery_price, 0) || 0;
    const totalDeliveryPriceMoscow = parseFloat(editData.delivery_price) || 0;
    const totalPrice = totalItemsPrice + comissionPrice + totalDeliveryPriceChina + totalDeliveryPriceMoscow;

    useEffect(() => {
      const fetchAndConvertPrice = async () => {
        const result = await convertPrice(totalPrice);
        setConvertedPrice(result);
      };
  
      fetchAndConvertPrice();
    }, [totalPrice]);

    return (
        <div>
            <Sidebar />
            <div className="main-content">
                <header className="section-order__header">
                    <div className="container">
                        <h1 className="title-1">Заказ {id}</h1>
                    </div>
                </header>
                <MessageTemplate
                    orderId={id}
                    price={totalPrice}
                    />

                <div className="info-block">
                    <div>
                    <div className="row">
                            <span className="row-title">Заказчик:</span>
                            <span>{user.username} ({user.telegram})</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Дата заказа:</span>
                            <span>{new Date(orderData.create_dt).toLocaleDateString()}</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Адрес доставки: </span>
                            <span>{orderData.delivery_location}</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Получатель:</span>
                            <span>{orderData.delivery_person}</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Телефон:</span>
                            <span>{orderData.delivery_person_phone}</span>
                        </div>
                    </div>
                    <div>
                        
                        <div className="row">
                            <span className="row-title">Товары:</span>
                            <span>{priceFormatter(totalItemsPrice)}¥</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Сервисный сбор:</span>
                            <span>{priceFormatter(comissionPrice)}¥</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Доставка по китаю:</span>
                            <span>{priceFormatter(totalDeliveryPriceChina)}¥</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Доставка в Москву:</span>
                            <span>{priceFormatter(totalDeliveryPriceMoscow)}¥</span>
                        </div>
                        <div className="row">
                            <span className="row-title">Итого:</span>
                            <span>{convertedPrice}</span>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <span className="row-title">Статус:</span>
                            <select name="status" value={statusMap[editData.status]} onChange={handleChange}>
                                {Object.entries(statusMap).map(([value, label]) => (
                                    <option key={value} value={label}>{label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="row">
                            <span className="row-title">Доставка в Москву:</span>
                            <input 
                                type="number" 
                                name="delivery_price" 
                                value={editData.delivery_price} 
                                onChange={handleChange} 
                                step="0.01" 
                            />
                        </div>
                        <div className="row">
                            <span className="row-title">Трек номер:</span>
                            <input 
                                type="text" 
                                name="track_number" 
                                value={editData.track_number} 
                                onChange={handleChange} 
                            />
                        </div>
                        <button onClick={handleSave}>Сохранить</button>
                    </div>
                </div>
                {orderData.rids?.map(rid => (
                    <RidInfo 
                        key={rid.rid} 
                        rid={rid.rid}
                        image={rid.image}
                        name={rid.name}
                        price={rid.price}
                        amount={rid.amount}
                        scas={rid.scas}
                        link={rid.link}
                        model={rid.model}
                        category={rid.category}
                        weight={rid.weight}
                        comment={rid.comment}
                        expanded={rid.expanded}
                        deliveryPrice={ridsData.find(r => r.rid === rid.rid)?.delivery_price || 0}
                        onDeliveryPriceChange={handleRidDeliveryPriceChange}
                    />
                ))}
            </div>
        </div>
    );
};

export default Order;
