import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import "./orderrow.css";

const statusMap = {
  4: 'Ожидает оплаты',
  5: 'Оплачен',
  6: 'Оформлен',
  7: 'Собран',
  8: 'В пути на склад',
  9: 'Отправлен получателю'
};

const OrderRow = ({ order, onCheckboxChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    onCheckboxChange(order.id, newCheckedState);
  };

  return (
    <div className="order-row">
      <div className="order-cell">
        <input 
          type="checkbox" 
          checked={isChecked} 
          onChange={handleCheckboxChange} 
        />
      </div>
      <div className="order-cell">
        <NavLink to={`/order/${order.id}`}>
          {order.id}
        </NavLink>
      </div>
      <div className="order-cell">{new Date(order.date).toLocaleDateString()}</div>
      <div className="order-cell">¥{order.price.toFixed(2)}</div>
      <div className="order-cell">{order.deliveryPerson}</div>
      <div className="order-cell">{statusMap[order.status]}</div>
    </div>
  );
};

export default OrderRow;
