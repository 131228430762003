// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-list {
    display: flex;
    align-content: center;
    margin: auto;
    gap: 20px;
    flex-direction: column;
    max-width: 1200px;
    
  }
  
  .orders-header {
    display: flex;
    background-color: #ccc;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
  }

  .order-row {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
  }
  
  .order-cell {
    flex: 1 1;
    margin: 0 auto;
    padding: 8px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .order-cell input[type="checkbox"] {
    margin: 0 auto;
  }
  
  .order-cell select {
    width: 100%;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/orderrow/orderrow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,YAAY;IACZ,SAAS;IACT,sBAAsB;IACtB,iBAAiB;;EAEnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,uCAAuC;IACvC,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uCAAuC;IACvC,cAAc;EAChB;;EAEA;IACE,SAAO;IACP,cAAc;IACd,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB","sourcesContent":[".orders-list {\n    display: flex;\n    align-content: center;\n    margin: auto;\n    gap: 20px;\n    flex-direction: column;\n    max-width: 1200px;\n    \n  }\n  \n  .orders-header {\n    display: flex;\n    background-color: #ccc;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    padding: 8px 0;\n  }\n\n  .order-row {\n    display: flex;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    padding: 8px 0;\n  }\n  \n  .order-cell {\n    flex: 1;\n    margin: 0 auto;\n    padding: 8px;\n    display: flex;\n    align-items: center;\n    text-align: center;\n  }\n  \n  .order-cell input[type=\"checkbox\"] {\n    margin: 0 auto;\n  }\n  \n  .order-cell select {\n    width: 100%;\n    padding: 4px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
