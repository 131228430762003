// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dash-block {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    width: 40%;
    text-align: center;
    align-items: center;
}

.wide-block {
    display: flex;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 85%;
    text-align: center;
    align-items: center;
}

.number-icon {
    padding: 15px;
    background-color: #ccc;
    border-radius: 100%;
}

.edit-button {
    margin-left: 15px;
    border: 0px;
    border-radius: 10px;
    background-color: transparent;
    height: 35px;
    color: black;
}

.edit-button:hover {
    background-color: gray;
}`, "",{"version":3,"sources":["webpack://./src/components/dblocks/dblocks.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uCAAuC;IACvC,gBAAgB;IAChB,UAAU;IACV,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,uCAAuC;IACvC,UAAU;IACV,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".dash-block {\n    display: flex;\n    justify-content: center;\n    gap: 30px;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    max-width: 300px;\n    width: 40%;\n    text-align: center;\n    align-items: center;\n}\n\n.wide-block {\n    display: flex;\n    justify-content: center;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    width: 85%;\n    text-align: center;\n    align-items: center;\n}\n\n.number-icon {\n    padding: 15px;\n    background-color: #ccc;\n    border-radius: 100%;\n}\n\n.edit-button {\n    margin-left: 15px;\n    border: 0px;\n    border-radius: 10px;\n    background-color: transparent;\n    height: 35px;\n    color: black;\n}\n\n.edit-button:hover {\n    background-color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
