import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const MessageTemplate = ({ orderId, price }) => {
    const [copySuccess, setCopySuccess] = useState('');

    const message = `Добрый день! Ваш заказ №${orderId} на сумму ${price}¥ обработан. 
Для оплаты переведите указанную сумму на сбербанк по номеру +79119119191.
Подробности на странице заказа: https://pwblogistics.com/orders/${orderId}`;

    const handleCopy = () => {
        navigator.clipboard.writeText(message).then(() => {
            setCopySuccess('Cкопировано!');
            setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
        }, () => {
            setCopySuccess('Ошибка при копировании');
        });
    };

    return (
        <div style={styles.message}>
            <div>
                Добрый день! Ваш заказ №{orderId} на сумму {price}¥ обработан. 
                <br />
                Для оплаты переведите указанную сумму на сбербанк по номеру +79119119191.
                <br />
                Подробности на странице заказа: https://pwblogistics.com/orders/{orderId}
            </div>
            <button style={styles.copyButton} onClick={handleCopy}>
                <FontAwesomeIcon icon={faCopy} />
            </button>
            {copySuccess && <div style={styles.copySuccess}>{copySuccess}</div>}
        </div>
    );
};

const styles = {
    message: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#e4ffe3',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '20px',
        width: '90%',
        margin: 'auto',
        marginBottom: '20px'
    },
    copyButton: {
        backgroundColor: 'transparent',
        border: 'none',
        color: 'black',
        cursor: 'pointer',
        fontSize: '18px',
        marginLeft: '10px'
    },
    copySuccess: {
        color: 'green',
        marginLeft: '10px'
    }
};

export default MessageTemplate;
