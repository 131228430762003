import './App.css';

import {Routes, Route } from "react-router-dom";
import Layout from "./utils/Layout";

import RequireAuth from "./utils/RequireAuth";
import PersistLogin from "./utils/PersistLogin";

import Login from "./pages/auth/Login";
import Home from './pages/Home/Home';
import Orders from './pages/Orders/Orders';
import Order from './pages/Order/Order';
import Customers from './pages/Customers';
import Requests from './pages/Requests';

function App() {
  return (
    <Routes>
    <Route path="/" element={<Layout />}>

    

      <Route path="/login" element={<Login />} />

      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth /> } >
          <Route path="/" element={<Home />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/order/:id" element={<Order />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/requests" element={<Requests />} />
        </Route>
      </Route>
    </Route>
    </Routes>
  );
}

export default App;
