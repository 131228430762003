// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'DM Sans', sans-serif;
}

button {
  background-color: #008a37;
  color: white;
  padding: 5px;
  border-radius: 5px;
  border-color: transparent;
}

button:disabled {
  background-color: gray;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,kCAAkC;AACpC;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".App {\n  text-align: center;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  min-height: 100vh;\n  font-family: 'DM Sans', sans-serif;\n}\n\nbutton {\n  background-color: #008a37;\n  color: white;\n  padding: 5px;\n  border-radius: 5px;\n  border-color: transparent;\n}\n\nbutton:disabled {\n  background-color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
