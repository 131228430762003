
import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import "./orderrow.css";



const CustomerRow = ({ user }) => {


  return (
   
    <div className="order-row">
 
      <div className="order-cell">
        
          {user.name}
      </div>
      <div className="order-cell">{user.telegram}</div>
      <div className="order-cell">{user.phone}</div>
      <div className="order-cell">{user.email}</div>
    </div>
   
  );
};

export default CustomerRow;
