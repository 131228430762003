
import "./orderrow.css";



const RequestRow = ({ request }) => {


  return (
   
    <div className="order-row">
 
      <div className="order-cell">
        
          {request.name}
      </div>
      <div className="order-cell">{request.phone}</div>
      <div className="order-cell">{request.telegram}</div>
      <div className="order-cell">{request.comment}</div>
    </div>
   
  );
};

export default RequestRow;
