import Sidebar from "../../components/sidebar/Sidebar";
import OrderRow from "../../components/orderrow/OrderRow";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import "./orders.css";

const Orders = () => {
  const axiosPrivate = useAxiosPrivate();
  const [ordersData, setOrders] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State for loading animation
  const [csvLink, setCsvLink] = useState(null); // State to store the CSV link

  useEffect(() => {
    const fetchOrdersData = async () => {
      try {
        const response = await axiosPrivate.get("/api/v1/private/orders/get");
        const orders = response.data.map(order => ({
          id: order.order_id,
          status: order.status,
          price: order.price,
          delivryLocation: order.delivery_location,
          deliveryPerson: order.delivery_person,
          deliveryTk: order.delivery_tk,
          deliveryPrice: order.delivey_price,
          trackNumber: order.track_number,
          date: order.create_dt,
        }));
        
        setOrders(orders);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };

    fetchOrdersData();
  }, [axiosPrivate]);

  const handleCheckboxChange = (orderId, isChecked) => {
    setSelectedOrderIds(prevSelectedOrderIds =>
      isChecked
        ? [...prevSelectedOrderIds, orderId]
        : prevSelectedOrderIds.filter(id => id !== orderId)
    );
  };

  const createCSV = async () => {
    setIsLoading(true); // Start loading
    setCsvLink(null); // Reset previous link

    try {
      const response = await axiosPrivate.post("/api/v1/private/orders/table",
        selectedOrderIds
      );

      setCsvLink(response.data); // Store the link from the response
    } catch (error) {
      console.error("Error creating CSV:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const ords = ordersData.map((order) => {
    return <OrderRow
      order={order}
      key={order.id}
      onCheckboxChange={handleCheckboxChange}
    />
  });

  return (
    <div>
      <Sidebar />
      <div className="main-content">
        <h1>Заказы</h1>
        <section className="buttons-section">
          <button onClick={createCSV} disabled={selectedOrderIds.length === 0 || isLoading}>
            Собрать в таблицу
          </button>
          {isLoading && <div className="loading-spinner"></div>} {/* Loading animation */}
          {csvLink && <a href={csvLink} target="_blank" rel="noopener noreferrer">Открыть таблицу</a>} {/* CSV link */}
        </section>
        
        <div className="orders-list">
          <div className="orders-header">
            <div className="order-cell"></div>
            <div className="order-cell">Номер заказа</div>
            <div className="order-cell">Дата</div>
            <div className="order-cell">Сумма</div>
            <div className="order-cell">Получатель</div>
            <div className="order-cell">Статус</div>
          </div>
          {ords}
        </div>
      </div>
    </div>
  );
};

export default Orders;
