import axios from '../api/axios';

const priceFormatter = new Intl.NumberFormat();

async function fetchExchangeRate() {
  const response = await axios.get('/api/v1/rate');
  return response.data; // Assuming the API returns an object with the rate field
}

export async function convertPrice(price) {
  const rate = await fetchExchangeRate();
  const convertedPrice = price * rate;
  return `${priceFormatter.format(price)} ¥ = ${priceFormatter.format(convertedPrice)} ₽`;
}

export async function convertPriceRub(price) {
    const rate = await fetchExchangeRate();
    const convertedPrice = price * rate;
    return convertedPrice;
  }