import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck } from '@fortawesome/free-solid-svg-icons';
import "./dblocks.css";

const RateBlock = () => {
    const [rate, setRate] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [newRate, setNewRate] = useState('');

    const axiosPrivate = useAxiosPrivate();


    useEffect(() => {
        // Fetch the current rate from the backend when the component mounts
        axiosPrivate.get('/api/v1/rate')
            .then(response => {
                setRate(response.data); // Assuming the response contains a field 'rate'
            })
            .catch(error => {
                console.error('There was an error fetching the rate!', error);
            });
    }, []);

    const handleEditClick = () => {
        setIsEditing(true);
        setNewRate(rate);
    };

    const handleInputChange = (event) => {
        setNewRate(event.target.value);
    };

    const handleSubmitClick = () => {
        axiosPrivate.post(`/api/v1/private/rate/set?rate=${newRate}`)
            .then(response => {
                setRate(newRate);
                setIsEditing(false);
            })
            .catch(error => {
                console.error('There was an error updating the rate!', error);
            });
    };

    return (
        <div className="dash-block">
            <h2>1 ¥ = {isEditing ? <input type="text" style={{maxWidth: '40px'}} value={newRate} onChange={handleInputChange} /> : `${rate}`} ₽</h2>
            <button className="edit-button" onClick={isEditing ? handleSubmitClick : handleEditClick}>
                <FontAwesomeIcon icon={isEditing ? faCheck : faEdit} className="icon" />
            </button>
        </div>
    );
}

export default RateBlock;
