import RequestRow from "../components/orderrow/RequetRow";
import Sidebar from "../components/sidebar/Sidebar";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

const Requests = () => {
    const axiosPrivate = useAxiosPrivate();
  const [requestsData, setRequests] = useState([]);


  useEffect(() => {
    // Fetch data from the backend
    const fetchRequestsData = async () => {
        try {
            const response = await axiosPrivate.get("/api/v1/private/requests");
            const requests = response.data.map(request => ({
                name: request.name,
                phone: request.phone,
                telegram: request.telegram,
                comment: request.comment,
            }));
            
            setRequests(requests);
        } catch (error) {
            console.error("Error fetching cart data:", error);
        }
    };

    fetchRequestsData();
}, [axiosPrivate]);

    const rqsts = requestsData.map((request) => {
        return <RequestRow
        request={request}
        key={request.name}
        />
    })

    return ( 
        <div>
            
            <Sidebar />
            <div className="main-content">
            <h1>Заявки на расчет</h1>

            <div className="orders-list">
            <div className="orders-header">
        <div className="order-cell">Имя</div>
        <div className="order-cell">Телефон</div>
        <div className="order-cell">Телеграм</div>
        <div className="order-cell">Коммент</div>
      </div>
                {rqsts}
            </div>
            
            </div>
        </div>
     );
}
 
export default Requests;